<template>
  <div class="container">
    <div @click="goback" style="font-size:14px;margin-bottom:10px;cursor: pointer;width:50px">
      <i class="el-icon-back"></i>
      返回
    </div>
    <el-form :model="form" :rules="rules" ref="form" style="width:800px">
      <el-form-item label="语言简码" prop="language_code" label-width="150px">
        <el-select v-model="form.language_code">
          <el-option
            v-for="item in languagelist"
            :key="item.code"
            :label="item.name"
            :value="item.code">
          </el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="标题信息" v-if="showMsg.title_type == 2" label-width="150px">
        <el-input v-model="form.title" type="text" autocomplete="off"></el-input>
      </el-form-item>
      <el-form-item label="调整地址类型" v-if="jumpType==2||jumpType==3" label-width="150px">
        <el-select v-model="form.jump_type" placeholder="请选择">
          <el-option label="外部url" :value="1"></el-option>
          <el-option label="内部url" :value="2"></el-option>
        </el-select>
      </el-form-item>
      <el-form-item v-if="showMsg.jump_url_type == 2||jumpType==3" label="单击图片的跳转地址" label-width="150px">
        <el-input v-model="form.jump_url" autocomplete="off"></el-input>
      </el-form-item>
      <el-form-item label="内容" v-if="showMsg.content_type!==1" label-width="150px">
        <el-input v-model="form.content" v-if="showMsg.content_type ==2" autocomplete="off"></el-input> <!--单行文本 -->
        <el-input v-model="form.content" :rows="5" v-if="showMsg.content_type ==3" type="textarea" autocomplete="off"></el-input><!--多行文本 -->
      </el-form-item>
      <el-form-item label="图文定义" v-if="showMsg.image_url_type!==1" label-width="150px">
        <!-- 图片或视频 -->
        <el-upload
        v-if="showMsg.image_url_type == 2||showMsg.image_url_type==3||showMsg.image_url_type==7"
          class="avatar-uploader"
          :action="s3url"
          :show-file-list="false"
          :http-request="fileUpload"
          @change="handelFileChange"
          :auto-upload="false"
        >
          <img v-if="imageUrl" :src="imageUrl" class="avatar">
          <i v-else class="el-icon-plus avatar-uploader-icon"></i>
        </el-upload>
        <!-- 富文本----数据处理参考nooie-User-activemsgadd -->
        <editor v-if="showMsg.image_url_type == 4 || showMsg.image_url_type == 5|| showMsg.image_url_type == 6" id="test" ref="editorone" style="z-index:990;width:650px;" @change="editorchange" v-model="detail"></editor>
      </el-form-item>
      <el-form-item label-width="150px">
        <el-button type="primary" v-if="!edit" @click="Validate(addconfirm)">新增</el-button>
        <el-button type="primary" v-if="edit" @click="Validate(editconfirm)">修改</el-button>
      </el-form-item>
    </el-form>

  </div>
</template>

<script type="text/ecmascript-6">
import util from '../../../util/util'
import Editor from "../../../components/richtext/index.vue";
export default {
  name: '',
  mixins: [],
  components: {
    Editor
  },
  props: {},
  data () {
    return {
      contentCode: '',
      ruleType: '',
      contentRuleCode: '',
      ID: '',
      imageUrl: '',
      form: {
        id: '',
        content: '',
        content_rule_code: '',
        img_url: '',
        jump_url: '',
        language_code: '',
        title: '',
        jump_type: ''
      },
      rules: {
      //  content_rule_code: [{ required: true, message: '请输入内容规则编号', trigger: 'blur' }],
      //  language_code: [{ required: true, message: '请输入语言简码', trigger: 'blur' }],
      },
      title: '',
      edit: false,
      showMsg: {},
      s3url: '',
      detail: '',
      upfile: {},
      downkey: '',
      languagelist: [],
      richtext: '',
      jumpType: ''
    }
  },
  computed: {},
  watch: {},
  methods: {
    Getlancode () {
      this.api.LanguageList({
        page: 1,
        limit: 100,
      }).then(res => {
        if (res.data.code == 200) {
          this.languagelist = res.data.data.data
          return
        }
        this.$message.error('获取失败' + res.data.msg)
      })
    },
    GetShowMsg(){
      this.api.RulePageList({
        page: 1,
        limit: 10,
        content_rule_code: this.contentRuleCode
      }).then(res => {
        if (res.data.code == 200) {
          this.showMsg = res.data.data.content_info
          return
        }
        this.$message.error('获取失败' + res.data.msg)
      })
    },
    GetInfo(){
      this.api.RulePageDetail({id: this.ID}).then(res => {
        if (res.data.code == 200) {
          this.form=res.data.data.data
          this.showMsg = res.data.data.content_info
          if (this.showMsg.image_url_type == 4 || this.showMsg.image_url_type == 5|| this.showMsg.image_url_type == 6) {
            this.Geteditor()
          } else if (this.showMsg.image_url_type == 2||this.showMsg.image_url_type==3||this.showMsg.image_url_type==7) {
            this.Geteditor()
          }
          return
        }
        this.$message.error('获取详情失败' + res.data.msg)
      })
    },
    Geteditor () {
      this.api.S3down({key:this.form.img_url}).then(res => {
        if (res.data.code == 200) {
          if (this.showMsg.image_url_type == 4 || this.showMsg.image_url_type == 5|| this.showMsg.image_url_type == 6) {
            var urlReg = /[a-zA-Z0-9][-a-zA-Z0-9]{0,62}(\.[a-zA-Z0-9][-a-zA-Z0-9]{0,62})+\.?/
             this.$axios.get('/aws'+ res.data.data.get_url.split(urlReg.exec(res.data.data.get_url)[0])[1]).then(res => {
               if (res.status === 200) {
                 this.$refs.editorone.setHtml(res.data)
              }
            })
          } else if (this.showMsg.image_url_type == 2||this.showMsg.image_url_type==3||this.showMsg.image_url_type==7) {
            this.imageUrl = res.data.data.get_url
          }
          // this.detail = res.data.data.get_url.split('?')[0]
          return
        }
        this.$message.error('下载S3失败:' + res.data.msg)
      })
    },
    Validate: function (call) {
      this.$refs['form'].validate(res => {
        if (res && call) {
          call()
        }
      })
    },
    update () {
      if (this.showMsg.image_url_type == 4 || this.showMsg.image_url_type == 5|| this.showMsg.image_url_type == 6) {
        let blob = new Blob([this.richtext],{type : 'text/html'})
        let file = new File([blob],'content.html')
        console.log(file,'文件')
        util.getFileMD5(file, md5 => {
          let params = {
            key_action: 'content_page',
            key_md5: md5,
            key_type: 'display',
            key_suffix: file.name.split('.')[1],
            content_length: file.size,
            content_type: 'html'
          }
          this.GetPreSign(file,params,'html')
        })
      } else {
        this.updateConfirm()
      }
    },
    updateConfirm () {
      this.form.content_rule_code = this.$route.params.code.split('&')[2]
      this.form.id = +this.form.id
      this.form.jump_type = +this.form.jump_type
      this.api.RulePageUpdate(this.form).then(res => {
        if (res.data.code == 200) {
          this.form = {}
          this.$message.success(this.title +'成功')
          if (!this.edit) {
            this.$confirm('是否返回列表页?', '提示', {
              confirmButtonText: '确定',
              cancelButtonText: '取消',
              type: 'warning'
            }).then(() => {
              this.goback()
            })
          }
          this.dialogFormVisible = false
          return
        }
        this.$message.error(this.title +'失败' + res.data.msg)
      })
    },
    addconfirm () {
      this.update()
    },
    editconfirm () {
      this.update()
    },
    // 文件上传
    fileUpload (file,type) {
      let config = {
        headers: {
          'Content-Type': file.type?file.type:'html',
          'X-Amz-Acl': 'public-read',
          'X-Amz-Server-Side-Encryption': "AES256"
        }
      }
      var urlReg = /[a-zA-Z0-9][-a-zA-Z0-9]{0,62}(\.[a-zA-Z0-9][-a-zA-Z0-9]{0,62})+\.?/
      let url = '/aws' + this.s3url.split(urlReg.exec(this.s3url)[0])[1]
      this.$axios.put(url, file, config).then(res => {
        if (res.status === 200) {
          this.$message.success('上传成功')
          if (type == 'html') {
            this.updateConfirm()
          }
        }
      }).catch( err => {
        console.log(err)
        this.$message.error('上传失败:'+err)
      })
    },
    editorchange (html) {
      // 1.获取内容url
      this.richtext = html
    },
    GetPreSign (file,params,type) {
      this.api.S3push(params).then(res => {
        if (res.data.code == 200) {
          this.s3url = res.data.data.put_url
          this.fileUpload(file,type)
          this.form.img_url = res.data.data.key
          if (type == 'picture') {
            // 下载相关
            this.downkey = res.data.data.key
            var urlReg = /[a-zA-Z0-9][-a-zA-Z0-9]{0,62}(\.[a-zA-Z0-9][-a-zA-Z0-9]{0,62})+\.?/
            this.imageUrl = '/aws' + res.data.data.get_url.split(urlReg.exec(res.data.data.get_url)[0])[1]
          }
          return
        }
        this.$message.error('预签名失败' + res.data.msg)
      }).catch(err => {
          console.log(err)
      })
    },
    // 预签名
    GetPicturepresign () {
      util.getFileMD5(this.upfile, md5 => {
        let params = {
          key_action: 'content_page',
          key_md5: md5,
          key_type: 'display',
          key_suffix: this.upfile.name.split('.')[1],
          content_length: this.upfile.size,
          content_type: this.upfile.type
        }
        this.GetPreSign(this.upfile,params,'picture')
      })
    },
    handelFileChange (event) {
      this.upfile = event.raw
      this.GetPicturepresign()
    },
    goback(){
      this.$router.push('/iot/contentmanage/rule/content/'+this.contentCode+'&'+this.ruleType+'&'+this.contentRuleCode)
    }
  },
  filters: {},
  mounted () {
    this.contentCode = this.$route.params.code.split('&')[0]
    this.ruleType = this.$route.params.code.split('&')[1]
    this.contentRuleCode = this.$route.params.code.split('&')[2]
    this.jumpType = this.$route.params.code.split('&')[3]
    this.ID = this.$route.params.code.split('&')[4]
    // 修改获取详情
    if(this.ID){
      this.GetInfo()
      this.title = '编辑'
      this.edit = true
    } else {
      this.edit = false
      this.GetShowMsg()
      this.title = '创建'
    }
  },
  created () {
    this.Getlancode()
  }
}
</script>

<style>
.avatar-uploader .el-upload {
    border: 1px dashed #d9d9d9;
    border-radius: 6px;
    cursor: pointer;
    position: relative;
    overflow: hidden;
  }
  .avatar-uploader .el-upload:hover {
    border-color: #409EFF;
  }
  .avatar-uploader-icon {
    font-size: 28px;
    color: #8c939d;
    width: 178px;
    height: 178px;
    line-height: 178px;
    text-align: center;
  }
  .avatar {
    width: 178px;
    height: 178px;
    display: block;
  }
</style>
